<template>
    <div class="wang-editor-wrap">
        <div :id="editorName"></div>
    </div>
</template>
<script>
    import E from 'wangeditor'

    export default {
        props: {
            parentComponent: String,
            isSensitive: Boolean,
            editorName: {
                type: String,
                default: 'wangEditor'
            },
            editContext: {
                default: ''
            }
        },
        components: {},
        data() {
            return {editor: "null"};
        },
        created() {

        },
        mounted() {
            this.initWangEditor();
            this.$vc.on(this.$route.path,
                this.editorName,
                "setWangEditorContent",
                this.setWangEditorContent
            );
            this.editor.txt.html(this.editContext);
        },
        methods: {
            initWangEditor() {
                // var E = window.wangEditor;
                this.editor = new E("#" + this.editorName);
                this.editor.config.uploadImgShowBase64 = true;
                this.editor.config.pasteFilterStyle = false;
                this.editor.config.menus = [
                    "head", // 标题
                    "bold", // 粗体
                    "fontSize", // 字号
                    "italic", // 斜体
                    "underline", // 下划线
                    "strikeThrough", // 删除线
                    "foreColor", // 文字颜色
                    "backColor", // 背景颜色
                    "link", // 插入链接
                    "list", // 列表
                    "justify", // 对齐方式
                    "quote", // 引用
                    "emoticon", // 表情
                    "image", // 插入图片
                    "table", // 表格
                ];

                this.editor.config.onchange = (html) => {
                    /*this.editorContent = html;*/
                    const wpsTags = [
                        "w:LsdException",
                        "w:Compatibility",
                        "w:SpaceForUL",
                        "o:OfficeDocumentSettings",
                        "w:WordDocument",
                        "w:LatentStyles",
                    ];
                    const options = {
                        whiteList: {
                            img: ["src", "style", "class"],
                        },
                        onTag(tag, html, options) {
                            return tag === "img"
                                ? `<div style="max-width:100%;">${html}</div>`
                                : html;
                        },
                        onIgnoreTag(tag, html, options) {
                            // 过滤从wps复制过来的标签信息
                            return wpsTags.includes(tag) ? "" : html;
                        },
                    };
                    html = filterXSS(html, options);
                    html = html.replace(/<font color="/gi, '<font style="color: ');
                    html = html.replace(/<font size="1/gi, '<font style="font-size: 10px');
                    html = html.replace(/<font size="2/gi, '<font style="font-size: 13px');
                    html = html.replace(/<font size="3/gi, '<font style="font-size: 16px');
                    html = html.replace(/<font size="4/gi, '<font style="font-size: 18px');
                    html = html.replace(/<font size="5/gi, '<font style="font-size: 24px');
                    html = html.replace(/<font size="6/gi, '<font style="font-size: 32px');
                    html = html.replace(/<font size="7/gi, '<font style="font-size: 48px');
                    console.log(html);
                    this.$vc.emit(this.$route.path,
                        this.parentComponent,
                        "changeEditor",
                        html.replace("<p></p>", "").replace("<p></p>", "")
                    );
                };
                this.editor.config.customUploadImg = (files, insert) => {
                    let photoFiles = files;
                    if (photoFiles && photoFiles.length > 0) {
                        // 获取目前上传的文件
                        photoFiles.forEach(async (file) => {
                            if (file.size > 1024 * 1024 * 2) {
                                this.$vc.toast("图片大小不能超过 2M!");
                                return false;
                            }
                            let formData = new FormData(); //新建FormData对象
                            formData.append("files", file);
                            formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                            formData.append("compress", "true");
                            // var reader = new FileReader(); //新建FileReader对象
                            // reader.readAsDataURL(file); //读取为base64
                            // reader.onloadend = async (e) => {
                                let imgSrc = await this.Constants.uploadFileOne(formData).
                                catch((err) => {
                                    this.$vc.toast("上传图片错误,请重新上传");
                                });

                                if (imgSrc && imgSrc != "") {
                                    imgSrc = this.Constants.BUCKET_NAMES.IMG.visitUrl +imgSrc
                                    let width = 400;
                                    let img = await this.getImgInfo(imgSrc);
                                    if (img.width < 400) {
                                        width = img.width;
                                    }
                                    //处理图片模糊
                                    // imgSrc +=
                                    //     "?x-oss-process=image/resize,w_" +
                                    //     width +
                                    //     "/quality,Q_100";
                                    if (this.isSensitive) {
                                        this.$emit("callback-photos", imgSrc);
                                    }
                                    insert(imgSrc);
                                }
                            // };
                        });
                    }
                };
                this.editor.create();
            },
            changeEditorStatus(status) {
                this.editor.$textElem.attr("contenteditable", status);
            },
            getWangEditorContent() {
                this.editor.txt.html();
            },
            getImgInfo(src) {
                return new Promise((resolve, reject) => {
                    let img = new Image();
                    img.src = src;
                    img.onload = () => {
                        resolve(img);
                    };
                });
            },
            setWangEditorContent(content) {
                /*this.editorContent = content;*/
                console.log('接受设置编辑器的数据', content, this.editor);
                this.editor.txt.html(content);
                // console.log(content);
            },
            clearWangEditorContent() {
                this.editor.txt.clear();
            },
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
        },
    };
</script>

<style lang="stylus" scoped>
    .wang-editor-wrap
        width var(--editor-width, 100%)

    #wangEditor
        width 100%
</style>
